import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Checkmark } from "react-checkmark";

import background from "../img/1.png";

export interface SuccessModalProps {
  show: boolean;
  hash: string;
  handleClose: () => void;
}

export default function SuccessModal(
  props: SuccessModalProps
): React.ReactElement {
  return (
    <Modal show={props.show} backdrop="static">
      <Modal.Body
        style={{
          backgroundImage: `url(${background})`,
          fontFamily: "Karla, Avenir",
        }}
      >
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <br></br>
          <br></br>
          <h3>
            <strong> 🎉 Congrats on your NFTheme!!! 🎉 </strong>
          </h3>
          <br></br>
          <br></br>
          {props.show && <Checkmark size="xxLarge" />}
          <br></br>
          <br></br>
          <div>
            <p>Transaction complete!</p>
            <br></br>
            <h5>
              <a
                href={`https://etherscan.io/tx/${props.hash}`}
                target="_blank"
                rel="noreferrer"
                style={{ color: "white" }}
              >
                View on Etherscan
              </a>
            </h5>
            <h5>
              <a
                href={`https://opensea.io/collection/nftheme`}
                target="_blank"
                rel="noreferrer"
                style={{ color: "white" }}
              >
                View OpenSea collection
              </a>
            </h5>
          </div>
        </div>
        <Button
          style={{
            backgroundColor: "green",
            borderColor: "green",
            color: "white",
            boxShadow: "6px 6px 4px -4px grey",
            width: "100%",
            marginTop: "2%",
          }}
          block
          variant="primary"
          size="lg"
          onClick={props.handleClose}
          type="submit"
        >
          You are a beast!
        </Button>
      </Modal.Body>
    </Modal>
  );
}
