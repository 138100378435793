import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import contract from "./NFThemes.json";

const web3 = createAlchemyWeb3(
  "https://eth-mainnet.alchemyapi.io/v2/sGkFwmgrpGllRJa7RFY2rb68adoC517H"
);
// const web3 = createAlchemyWeb3(
//   "https://eth-rinkeby.alchemyapi.io/v2/VnIMKr4_ciMRq6ag01PuE94YZ4djhsH2"
// );
const CONTRACT_ADDRESS = "0x073d86752b6f88c59d4058f5979d17ad014fd078";
const PRICE_ETH = "0.1";

const nftContract = new web3.eth.Contract(
  contract.abi as any,
  CONTRACT_ADDRESS
);

export async function mintNFT(): Promise<{
  success: boolean;
  status: string;
  hash: string | undefined;
}> {
  const typedWindow = window as any;
  const publicKey = typedWindow.ethereum.selectedAddress;
  const mintPrice = parseInt(web3.utils.toWei(PRICE_ETH, "ether")).toString(16);
  const method = nftContract.methods.mint(publicKey);

  const gasEstimate = await method.estimateGas({
    gas: 5000000,
    value: parseInt(web3.utils.toWei(PRICE_ETH, "ether")),
  });

  const tx = {
    from: publicKey,
    to: CONTRACT_ADDRESS,
    gas: gasEstimate.toString(16),
    value: mintPrice,
    // data: nftContract.methods.setPriceWei("1000000000000000").encodeABI(),
    data: method.encodeABI(),
    // data: nftContract.methods.mint(publicKey).encodeABI(),
    // data: nftContract.methods.setFullMintActive(true).encodeABI(),
    // data: nftContract.methods
    //   .withdraw(web3.utils.toWei("0.1", "ether"))
    //   .encodeABI(),
    // data: nftContract.methods.setBaseURI("https://google.com/").encodeABI(),
    // data: nftContract.methods.setMaxSupply(16).encodeABI(),
    // data: nftContract.methods.addPresaleAddress(publicKey).encodeABI(),
  };

  //sign the transaction via Metamask
  try {
    const txHash = await typedWindow.ethereum.request({
      method: "eth_sendTransaction",
      params: [tx],
    });
    return {
      success: true,
      status:
        "✅ Check out your transaction on Etherscan: https://rinkeby.etherscan.io/tx/" +
        txHash,
      hash: txHash,
    };
  } catch (error: any) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
      hash: undefined,
    };
  }
}
