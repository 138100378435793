import React, { useState, useEffect } from "react";
import Web3 from "web3";
import background from "./img/4950.png";
import "./App.css";

import { Button, Container, Row, Col, Navbar } from "react-bootstrap";

import { mintNFT } from "./web3/mintNft";
import SuccessModal from "./components/SuccessModal";

import "bootstrap/dist/css/bootstrap.min.css";
import { Mixpanel } from "./util/Mixpanel";

const MINT_DATE = new Date("Mon Oct 25 2021 10:00:00 PM EDT").getTime();

function App() {
  const [txHash, setTxHash] = useState<string | undefined>();
  const [txSuccess, setTxSuccess] = useState<boolean | undefined>();
  const [walletConnected, setWalletConnected] = useState<boolean>(false);
  const [walletAddress, setWalletAddress] = useState<string>();
  const [countdownTime, setCountdownTime] = useState<{
    h: number;
    m: number;
    s: number;
  }>({ h: 0, m: 0, s: 0 });

  useEffect(() => {
    Mixpanel.track("Mint visited");
    const typedWindow = window as any;
    const checkConnection = async () => {
      // Check if browser is running Metamask
      let web3: any;
      if (typedWindow.ethereum) {
        web3 = new Web3(typedWindow.ethereum);
      } else if (typedWindow.web3) {
        web3 = new Web3(typedWindow.web3.currentProvider);
      }

      // Check if User is already connected by retrieving the accounts
      web3?.eth.getAccounts().then(async (addr: string) => {
        if (addr && addr.length > 0) {
          console.log(addr[0]);
          setWalletConnected(true);
          setWalletAddress(addr[0]);
        }
      });
    };
    checkConnection();

    if (typedWindow.ethereum) {
      typedWindow.ethereum.on("accountsChanged", function (accounts: string[]) {
        if (accounts.length > 0) {
          setWalletAddress(accounts[0]);
          setWalletConnected(true);
        }
      });
    }
    document.body.style.backgroundColor = "#AEA8C7";

    const time = secondsToTime((MINT_DATE - new Date().getTime()) / 1000);
    setCountdownTime(time);
    setInterval(() => {
      const time = secondsToTime((MINT_DATE - new Date().getTime()) / 1000);
      setCountdownTime(time);
    }, 1000);
  }, []);

  function secondsToTime(secs: number): { h: number; m: number; s: number } {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        height: "100vh",
        fontFamily: "Karla, Avenir",
      }}
    >
      <br></br>
      <Container>
        <Row>
          <Col xs={1}></Col>

          <Col>
            <Navbar>
              <Navbar.Brand href="/">
                <h2> 💡 NFThings </h2>
              </Navbar.Brand>
              <Navbar.Toggle />

              <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                  {walletConnected ? (
                    <p
                      style={{
                        textAlign: "center",
                        color: "black",
                      }}
                    >
                      Connected Wallet: {walletAddress}
                    </p>
                  ) : (
                    <Button
                      style={{ width: "200px", fontSize: "18px" }}
                      variant="dark"
                      size="lg"
                      onClick={() => {
                        const typedWindow = window as any;
                        if (typedWindow.ethereum) {
                          typedWindow.ethereum
                            .enable()
                            .then((accounts: any) => {
                              console.log(accounts);
                              setWalletConnected(true);
                              setWalletAddress(accounts[0]);
                              // Metamask is ready to go!
                            })
                            .catch((reason: any) => {
                              console.error(reason);
                              // Handle error. Likely the user rejected the login.
                            });
                        } else {
                          // The user doesn't have Metamask installed.
                        }
                        Mixpanel.track("Connect Wallet Button Clicked", {
                          site: "Mint",
                        });
                      }}
                    >
                      Connect Wallet
                    </Button>
                  )}
                </Navbar.Text>
              </Navbar.Collapse>
            </Navbar>
          </Col>
          <Col xs={1}></Col>
        </Row>
      </Container>
      <Container>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <Row>
          <Col xs={2}></Col>

          <Col>
            <div
              style={{
                textAlign: "center",
              }}
            >
              <h1 style={{ fontSize: "350%" }}>
                You Should Really Mint Our NFT
              </h1>
              <br></br>

              {/* THIS IS NOT LIVE YET, USE RINKEBY */}
              <h4>It could be a rare one like the one in the background 😜</h4>
              <br></br>
            </div>

            <div
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              {walletConnected ? (
                <Button
                  style={{
                    width: "500px",
                    fontSize: "30px",
                    color: "black",
                    background: "linear-gradient(90deg, red, gold)",
                  }}
                  disabled={MINT_DATE - new Date().getTime() < 0 ? false : true}
                  variant="dark"
                  size="lg"
                  onClick={async () => {
                    const response = await mintNFT();
                    console.log(response.status);
                    setTxSuccess(response.success);
                    setTxHash(response.hash);
                    Mixpanel.track("Mint Button Clicked", { site: "Mint" });
                  }}
                >
                  {MINT_DATE - new Date().getTime() < 0
                    ? "Mint"
                    : `${countdownTime.h}hrs, ${countdownTime.m}min, ${countdownTime.s}s`}
                </Button>
              ) : (
                <Button
                  style={{
                    width: "500px",
                    fontSize: "30px",
                    background: "linear-gradient(30deg, blue, purple)",
                  }}
                  variant="dark"
                  size="lg"
                  onClick={() => {
                    const typedWindow = window as any;
                    if (typedWindow.ethereum) {
                      typedWindow.ethereum
                        .enable()
                        .then((accounts: any) => {
                          console.log(accounts);
                          setWalletConnected(true);
                          setWalletAddress(accounts[0]);
                          // Metamask is ready to go!
                        })
                        .catch((reason: any) => {
                          console.error(reason);
                          // Handle error. Likely the user rejected the login.
                        });
                    } else {
                      // The user doesn't have Metamask installed.
                    }
                    Mixpanel.track("Connect Wallet Button Clicked", {
                      site: "Mint",
                    });
                  }}
                >
                  Connect Wallet
                </Button>
              )}
              <SuccessModal
                show={txSuccess ?? false}
                handleClose={() => setTxSuccess(undefined)}
                hash={txHash ?? ""}
              ></SuccessModal>
            </div>
          </Col>
          <Col xs={2}></Col>
        </Row>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>

        <Row>
          <Col xs={2}></Col>
          <Col>
            <h2> FAQ </h2>
            <hr />
            <h4> How many NFThemes are there? </h4>
            <p>
              <strong> Only 4,999 will ever be created. </strong>
            </p>
            <h4> What do I get with my NFTheme? </h4>
            <p>
              {" "}
              <strong>
                {" "}
                You will get an image representation of the NFTheme as an NFT in
                your ETH wallet. Not only is it great modern art, but NFThemes
                have real-world utility in different products dependent on the
                roadmap.{" "}
              </strong>
            </p>
            <h4> Where can I learn more about the NFThings community? </h4>
            <p>
              {" "}
              <strong>
                {" "}
                You can head to our main website to learn about the different
                products we've built and join the Discord!{" "}
              </strong>{" "}
            </p>
            <div style={{ color: "white", fontStyle: "bold" }}>
              <a
                href={`https://opensea.io/collection/nftheme`}
                target="_blank"
                rel="noreferrer"
                style={{ color: "white" }}
              >
                View OpenSea collection
              </a>
              &nbsp;|&nbsp;
              <a
                href={`https://discord.gg/fW4jCushU9`}
                target="_blank"
                rel="noreferrer"
                style={{ color: "white" }}
              >
                Join Discord
              </a>
              &nbsp;|&nbsp;
              <a
                href={`https://nfthings.app`}
                target="_blank"
                rel="noreferrer"
                style={{ color: "white" }}
              >
                NFThings Website
              </a>
            </div>
          </Col>
          <Col xs={2}></Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
